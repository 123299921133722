<template>
   <!-- 店铺分析 -->
   <div
    class="storage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
    >
    <div class="append">
       <div class="seach">
         <el-select 
          style="width:245px; 
          margin-right: 20px" 
          placeholder="请选择店铺" 
          v-model="pageData.profileId"
        >
          <el-option-group 
            :key="group.id"
            :label="group.name"
            v-for="group in storeSetList"
          >
           <el-option 
             v-for="item in group.marketPlaceTree"
             :key="item.profileId"
             :label="group.name+'-'+item.name"
             :value="item.profileId"
             @click.native="checkGroup(group.id, item.id)"
            >{{ item.name }}
           </el-option>
          </el-option-group>
         </el-select> 
         <TimeQuantum
           style="margin-right:10px" 
           @selectFinish='topTime'
           section="近7天"
           @sectionFinish='sectionSure'
         ></TimeQuantum>
         <el-button type="primary" icon="el-icon-search" @click="tableSearch">查询</el-button>
         <el-button-group style="margin-left: 150px">
           <el-button size="medium" type="primary" plain icon="el-icon-s-fold" @click="onClickTable">表格</el-button>
           <el-button size="medium" type="primary" plain icon="el-icon-s-data" @click="onClickGraphic">图形</el-button>
         </el-button-group>
         <el-table
           border
           :data="tableDataList"
           style="width: 100%"
           v-show="tableShow"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center', height: '40px'}"
          >
            <el-table-column label="日期" prop="date" align="center" width="150px" fixed :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="shopSalePrice" align="center" width="130px" fixed>
              <template slot="header">
                <span>销售额(¥)</span>
                <el-tooltip effect="dark" placement="bottom">
                  <div slot="content">
                    <span>所指的是：<span style="color: #FF8C00">商品的销售额（欧洲市场shipped状态订单已扣VAT），而非订单金额</span>, 即不包含各项税费, </span><br/>
                    <span>运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                    <span>计算逻辑：销售订单<span style="color: #FF8C00">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                    <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                    <span>① 销售订单的归属日期，以下单时间进行统计;</span><br/>
                    <span>② 如果订单发生退款，此订单会正常统计销售额，对应的退款金额会以退款时间，统计到当日总支出的退款项中;</span><br/>
                    <span>③ 部分pending订单，亚马逊一段时间内，返回的销售数据可能为0,</span><br/>
                    <span>此时系统会按最近成交的情况进行预算<span style="color: #FF8C00">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="shopOrderPrice" align="center" width="130px" fixed>
              <template slot="header">
                <span>订单金额(¥)</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>买家实际支付金额的总和</span><br/>
                    <span style="color: #FFA500">（包括销售额、包装费、税费、运费等费用, 但扣除促销金额）。</span><br/>
                    <span>特别说明：部分暂时无法获取支付金额的pending订单, </span><br/>
                    <span>此时系统会按最近成交的情况进行预算, 预算的值=销售额（商品价格*购买数据）。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="fbaCost" align="center" width="120px">
              <template slot="header">
                <span>FBA费用</span>
                <el-tooltip effect="dark" placement="top" content="亚马逊收取的FBA配送费用的总和。">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="orderCounts" align="center" width="120px">
              <template slot="header">
                <span>订单量</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>销售订单<span style="color: #FFA500">（不包括：取消、预售未付款授权 状态的订单）</span>的数量。</span><br/>
                    <span>1、销售订单的归属日期, 以下单时间进行统计;</span><br/>
                    <span>2、如果订单发生退款, 此订单会正常统计订单量;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="saleCounts" align="center" width="120px">
              <template slot="header">
                <span>销量</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>销售订单<span style="color: #FFA500">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品数量的总和。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="totalCost" align="center" width="120px">
              <template slot="header">
                <span>总支出</span>
                <el-tooltip effect="dark" placement="bottom">
                  <div slot="content">
                    <span>所有支出的总和，支出项包括：广告花费、FBA费用、亚马逊佣金、促销费用、退款金额 + 退款费用、</span><br/>
                    <span>亚马逊其它费用、系统设置商品成本 、系统设置其它成本 。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：1、如果亚马逊平台代收了增值税，</span><br/>
                    <span>则亚马逊其它费用中会包含增值税，即总支出中包括增值税; </span><br/>
                    <span>2、coupon类促销订单，系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用, </span><br/>
                    <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额；</span><br/>
                    <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                    <span>2、部分pending订单，亚马逊短时间内，返回的销售数据可能为0,</span><br/>
                    <span>此时系统会按最近成交的情况进行预算<span style="color: #FFA500">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                    <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #FFA500">之外的其它费用总和</span>, </span><br/>
                    <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的，但是这部分费用会被一并统计到总支出的促销费用中, </span><br/>
                    <span>因此需要进行对冲。b、增值税亚马逊未代收, 此时增值税需要加入到收入中, 对于费用来说是负数；c、FBM订单,</span><br/>
                    <span>运费需要加入到收入中，对于费用来说是负数；</span><br/>
                    <span>4、系统设置其它成本：店铺实际运营过程中，产生的一些特殊费用，可以是收入,</span><br/>
                    <span>也可以是支出，用于准确计算利润，可在“库存管理 - 其它成本”中进行上传;</span><br/>
                    <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用,</span><br/>
                    <span>可在“库存管理 - 商品成本”中进行设置或者上传；;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="" prop="advertiseCost" align="center">
              <template slot="header">
                <span>广告花费(¥)</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>广告被点击，所产生的总费用，相当于您的<span style="color:#FFA500">广告总成本</span> 。</span><br/>
                    <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                    <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</span><br/>
                    <span>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                    <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                    <span>3、计算规则：广告花费 = CPC竞价 * 被点击次数;</span><br/>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="shopReferralPrice" align="center">
              <template slot="header">
                <span>亚马逊佣金(%)</span>
                <el-tooltip effect="dark" placement="top" content="亚马逊收取的平台费用的总和。">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="promotionCost" align="center" width="120px">
              <template slot="header">
                <span>促销费</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>促销所产生的相关费用, 例如, 折扣对应的费用等都计入成本。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用,</span><br/>
                   <span>即总支出中包括coupon的使用费用, 因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="backOrderCounts" align="center" width="120px">
              <template slot="header">
                <span>退货数</span>
                <el-tooltip effect="dark" placement="top" content="退款订单中的商品数量的总和">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="backOrderRate" align="center">
              <template slot="header">
                <span>退货率(%)</span>
                <el-tooltip effect="dark" placement="top" content="计算规则：退货率 = 退货量 / 销售量 * 100%">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="backOrderCost" align="center">
              <template slot="header">
                <span>退货/款金额</span>
                <el-tooltip effect="dark" placement="top" content="退款时, 给客户退款金额的总和。">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="backOrderAmount" align="center">
              <template slot="header">
                <span>退货/款费用</span>
                <el-tooltip effect="dark" placement="top" content="退款时, 亚马逊收取的费用和返还费用的总和。  ">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="amzOtherCost" align="center">
              <template slot="header">
                <span>亚马逊其他费用</span>
                <el-tooltip effect="dark" placement="bottom">
                  <div slot="content">
                    <span>亚马逊订单明细中，除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #FFA500">之外的其它费用总和</span>。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：1、如果亚马逊平台代收了增值税，则亚马逊其它费用中会包含增值税。</span><br/>
                    <span>2、此值出现负数的原因是：</span><br/>
                    <span>a、有些促销活动是亚马逊平台搞的，但是这部分费用会被一并统计到总支出的促销费用中，因此需要进行对冲。</span><br/>
                    <span>b、增值税亚马逊未代收，此时增值税需要加入到收入中，对于费用来说是负数。</span><br/>
                    <span>c、FBM订单，运费需要加入到收入中，对于费用来说是负数；</span>      
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template> 
            </el-table-column>
            <el-table-column prop="productCost" align="center" width="120px">
              <template slot="header">
                <span>商品成本</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>包括商品的采购成本、物流成本（头程 / FBM）、其它费用,</span><br/>
                    <span>可在“库存管理 - 商品成本”中进行设置或者上传。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="otherCost" align="center" width="120px">
              <template slot="header">
                <span>其他成本</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>店铺实际运营过程中，产生的一些特殊费用，可以是收入，也可以是支出,</span><br/>
                    <span>用于准确计算利润，可在“库存管理 - 其它成本”中进行上传。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：系统设置其它成本，只会被统计到店铺维度的总支出中，并参与利润计算。</span><br/>
                    <span><span style="color: #FFA500">但不会被均分到商品维度</span>的总支出中，参加商品利润的计算。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="grossProfit" align="center" width="120px">
              <template slot="header">
                <span>毛利润(¥)</span>
                <el-tooltip effect="dark" placement="top" content="计算规则：毛利润 = 销售额 - 总支出">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="grossProfitRate" align="center" width="120px">
              <template slot="header">
                <span>毛利率(%)</span>
                <el-tooltip effect="dark" placement="top" content="计算规则：毛利率 = 毛利润 / 销售额 * 100%">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="adSalePrice" align="center" width="120px">
              <template slot="header">
                <span>广告销售额</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span> 通过广告带来的销售额。</span><br/>
                    <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                    <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</span><br/>
                    <span>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                    <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column  prop="adExposureCounts" align="center" width="120px">
              <template slot="header">
                <span>广告曝光量</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>广告投放给买家的展示总数。</span><br/>
                    <span>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="adClickCounts" align="center" width="120px">
              <template slot="header">
                <span>广告点击量</span>
                <el-tooltip effect="dark" placement="top" content="广告被点击的次数">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="adOrderCounts" align="center" width="120px">
              <template slot="header">
               <span>广告订单量</span>
               <el-tooltip effect="dark" placement="top">
                 <div slot="content">
                   <span>通过广告带来的订单量。</span><br/>
                   <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                   <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                   <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                   <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                 </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>  
              </template>
            </el-table-column>
            <el-table-column prop="adOrderRate" align="center" width="120px">
              <template slot="header">
                <span>广告订单比</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>通过广告带来的订单量占总订单量的比例。</span><br/>
                    <span>计算规则：广告订单量占比 = 广告订单量 / 订单量 * 100%</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="adClickRate" align="center" width="120px">
              <template slot="header">
                <span>广告点击率</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>每一次曝光被点击的概率。</span><br/>
                    <span>计算规则：广告点击率 = 点击量 / 曝光量 * 100%</span><br/>
                    <span>亚马逊广告点击率一般在0.6%-2.5%左右。</span><br/>
                    <span>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</span><br/>
                    <span>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</span><br/>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="cvr" align="center" width="120px">
              <template slot="header">
                <span>CVR</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>每一次点击带来订单的概率。</span><br/>
                    <span>计算规则：CVR = 订单数 / 点击量 * 100%;</span><br/>
                    <span>例如：CVR为10%，每100次点击，能带来10笔广告订单。</span><br/>
                    <span>亚马逊广告，CVR一般为5-10%左右</span><br/>
                    <span>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动</span><br/>
                    <span>如果CVR低，建议您优化listing，根据商品的受众人群</span><br/>
                    <span>提炼卖点、设计精美的场景图、善用Q&A，Review等。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="visitCounts" align="center" width="130px">
              <template slot="header">
                <span>访问次数</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>用户访问您的 Amazon.com 页面的次数。24 小时内的所有活动都将视为一次会话。</span><br/>
                    <span>数据来自于亚马逊后台业务报告，可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：按SKU维度查看数据时，如果1个ASIN下有多个SKU，导入的ASIN业务报告数据, </span><br/>
                    <span>只会随机填充到一个SKU，这会导致其它的SKU没有业务报告导入的相关数据。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="visitRate" align="center" width="130px">
              <template slot="header">
                <span>访问转化率</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>计算规则：访问转化率 = 销量 / 访问次数 * 100%</span><br/>
                    <span>访问次数，数据来自于亚马逊后台业务报告，可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                    <span><span style="color: #FFA500">特别说明</span>：按SKU维度查看数据时，如果1个ASIN下有多个SKU，导入的ASIN业务报告数据, </span><br/>
                    <span>只会随机填充到一个SKU，这会导致其它的SKU没有业务报告导入的相关数据。</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
         </el-table>
         <!-- echarts 区域 -->
         <div v-show="echartsShow">
           <div class="echartClass" id="echartId"></div>
         </div>
         <TimeQuantum 
           style="margin-right:10px" 
           @selectFinish='selectedEvent'
           section="近7天"
         ></TimeQuantum>
         <el-select
           placeholder="请选择类型"
           v-model="pageInfomation.type"
           @change="changeTypeName"
          >
           <el-option
             v-for="item in TypeLists"
             :key="item.id"
             :label="item.value"
             :value="item.id"
            >
           </el-option>
         </el-select>
         <div class="storeClass" id="storeId" v-loading='barLoading' element-loading-text="拼命加载中"></div>
       </div>
    </div>
   </div>
</template>

<script>
var moment = require("moment");
import TimeQuantum from '@/components/TimeQuantum'
import {
  getListByDate,   // 单店铺  时间段查询
  indicatorItemInit, 
  getListByItem,   // 多店铺，单项目查询
}from "@/api/businessAnalysis/storeAnalysis.js"

import {
  productAnalysisPageInit,  // 查询初始化
}from "@/api/businessAnalysis/productAnalysis.js"
import LoginVue from '../Login.vue';
export default {
   components:{
     TimeQuantum
   },
   data() {
     return {
       barLoading: false,
       loading: false,
       pageData: {
         profileId: "",  // 店铺
         dateTypeName: "",
         startDate: "",  // 开始时间
         endDate: ""   //  结束时间
       },
       pageInfomation: {
         type: "",
         dateTypeName: "",
         startDate: "",  // 开始时间
         endDate: ""  // 结束时间
       },
       hash: [],
       TypeLists: [],
       storeSetList: [],
       tableDataList: [],
       storeDateList: [],
       legendData: [],
       tableShow: false,
       echartsShow: false,
       chartInatance: null,  // 保存chartsInstance 数据
       charStoretInatance: null,  //  保存chartsInstance 数据
       shopId: "",   // 店铺id
       marketplaceId: "" , // 站点id,
       legendList:[],
       serise: []
     }
   },
   created() {
     this.$nextTick(()=> {
       setTimeout(()=> {
         this.tableSearch();
       }, 500)
     })
     this.getPageInitData();
     this.$nextTick(()=> {
       setTimeout(()=> {
         this.getStoreInfoInit();
         this.handleSearch();
       }, 1000)
     })
   },
   mounted() {
     this.tableShow = true;
     // this.echartsShow = true;
   },
   // 组件销毁的时候
   destroyed() {},

   methods: {
     topTime(val) {
       this.pageData.startDate = val.value[0];
       this.pageData.endDate = val.value[1];
       this.tableSearch();
       // this.getInfoInit();
     },
     // 列表查询 
     tableSearch() { 
       this.loading = true;
       let data = {
         startDate: this.pageData.startDate,
         endDate: this.pageData.endDate,
         shopId: this.shopId,
         marketplaceId: this.marketplaceId
       }
       getListByDate(data)
       .then(res=> {
         this.loading = false;
         this.tableDataList = [];
         if(res.data.code == 200) {
           this.tableDataList = res.data.data;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
       this.getIndicatorItemInit();
     },
     handleSearch(val) {
       this.barLoading = true;
       let data = {
         startDate: this.pageInfomation.startDate,
         endDate: this.pageInfomation.endDate,
         itemName: val
       }
       getListByItem(data)
       .then(res=> {
         this.barLoading = false;
         this.storeDateList = [];
         this.serise = [];
         const serise = [];
         const arrData = [];
         const info = [];
         if(res.data.code == 200) {
            this.storeDateList = res.data.data.dateList.reverse();
            res.data.data.vales.forEach((item,index)=>{
              const name=`${item.shopName}-${item.marketplaceName}`
              const flag = serise.some(i => {
                // info.push(item[this.pageInfomation.type])
                return i.name==name;
              })
              if(!flag){
                serise.push({
                  name: name,
                  smooth:true,
                  type: 'line',
                  data: [item[this.pageInfomation.type]],}
                )
              }else {
                serise.forEach((j,ind)=>{
                  if(j.name==name){
                    j.data.push(item[this.pageInfomation.type])
                  }
                })
              }
            })
            serise.forEach(k=>{
              arrData.push(k.name)
            })
            this.serise=serise
            this.legendList = arrData;
            console.log(this.serise);
            this.getStoreInfoInit()
         }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
         }
       })
       this.getIndicatorItemInit();
     },
     checkGroup(parentId, subId) {
       this.shopId = parentId;
       this.marketplaceId = subId;
     },
     selectedEvent(val) {
       this.pageInfomation.startDate = val.value[0];
       this.pageInfomation.endDate = val.value[1];
       this.handleSearch(this.pageInfomation.type);
     },
     changeTypeName(val) {
       this.pageInfomation.type = val;
       this.handleSearch(val);
     },
     getPageInitData() {
       productAnalysisPageInit()
       .then(res=>{
         let result = res.data.data;
         this.storeSetList = [];
         if(res.data.code == 200) {
           this.storeSetList = result.tress;
           this.storeSetList.forEach(item=> {
             item.marketPlaceTree.forEach(data=> {
               if(data.isSelected == 1) {
                 this.pageData.profileId = data.profileId;
                 this.shopId = item.id;
                 this.marketplaceId = data.id;
               }
             })
           })
         }
       })
     },
     // 点击表格 
     onClickTable() {
       this.tableShow = true;
       this.echartsShow = false;
     },
     // 点击图形
     onClickGraphic() {
       this.tableShow = false;
       this.echartsShow = true; 
       this.$nextTick(()=> {
         this.getInfoInit();
       }) 
      //  this.tableSearch();
     },
      // 指标项目初始化
      getIndicatorItemInit() {
        indicatorItemInit()
          .then(res=> {
           if(res.data.code == 200) {
             this.TypeLists = res.data.data;
             res.data.data.forEach(option=> {
               this.legendData.push(option.value);
             })
           }
        })
      },
     // 初始化信息 
     getInfoInit() {
       this.chartInatance  = this.$echarts.init(document.getElementById('echartId'));
       var xData = [];
       this.tableDataList.forEach(item=> {
        xData.push(item.date);
        xData = xData.reverse()
       })
       var option = {
         toolbox: {   //  设置可以保存图片
           show: true,
           feature: {        //  feature: (功能含义...)
             saveAsImage: {},  // 导出图片 (不需要配置任何项)
             dataView: {readOnly: false},  // 数据视图
             dataZoom: {},   // 区域缩放
             magicType: {
                type: ['bar', 'line']    // 在折线图和柱状图之间切换
             },
             restore: {},    // 重置功能  (还原数据功能)
           },
           x:'right',
           y:'top'
         },
         tooltip: {
           trigger: 'axis'
         },
         legend: {     // legend:  图例进行筛选
           orient: 'vertical',
           x:'right', 
           y:'center', 
           padding:[ 0, 30, 0, 0 ],
           icon: 'circle',
           data: this.legendData,   // legend:  图例中的data 是一个数组。
         },
         grid: {
           show: true,
           left: 100,
           top: 80,
           bottom: 150,
           width: '90%',
           height: 500,
         },
         xAxis: {
           type: 'category',
           scale: true,   // 脱离 0 缩放比
           data: xData,
           axisLabel: {
             interval: 0, // 坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
             rotate: 15, // 调整数值改变倾斜的幅度（范围-90到90）
           },
           boundaryGap: false   // 设置为 false
         },     
         yAxis: {
           type: 'value',
           scale: true,  // 脱离 0 值缩放比
         },
         series: [
              {
                name: '销售额',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(x => x.shopSalePrice)
              },
              {
                name: '订单量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(i => i.orderCounts)
              },
              {
                name: '销量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(t => t.saleCounts)
              },
              {
                name: '退货量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(it => it.backOrderCounts)
              },
              {
                name: '总支出',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(item => item.totalCost)
              },
              {
                name: '广告花费',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(data => data.advertiseCost)
              },
              {
                name: 'FBA费用',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(op => op.fbaCost)
              },
              {
                name: '亚马逊佣金',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(g => g.shopReferralPrice)
              },
              {
                name: '促销费用',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(k => k.promotionCost)
              },
              {
                name: '退款金额',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(f => f.backOrderCost)
              },
              {
                name: '退款费用',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(e => e.backOrderAmount)
              },
              {
                name: '退款/退货率',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(aitem => aitem.backOrderRate)
              },
              {
                name: '亚马逊其他费用',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(code => code.amzOtherCost)
              },
              {
                name: '商品成本',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(fa => fa.productCost)
              },
              {
                name: '其他成本',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(table => table.otherCost)
              },
              {
                name: '毛利润',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(tab => tab.grossProfit)
              },
              {
                name: '毛利率',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(ol => ol.grossProfit)
              },
              {
                name: 'ACoTS',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(option => option.acots)
              },
              {
                name: '广告销售额',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(opt => opt.adSalePrice)
              },
              {
                name: 'ASoTS',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(about => about.asots)
              },
              {
                name: '广告曝光量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(v => v.adExposureCounts)
              },
              {
                name: '广告点击量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(j => j.adClickCounts)
              },
              {
                name: '广告点击率',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(s => s.adClickRate)
              },
              {
                name: '广告订单量',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map(z => z.adOrderCounts)
              },
              {
                name: '广告订单比',
                smooth:true,
                type: 'line',
                stack: 'all',
                data: this.tableDataList.map( _index=> _index.adOrderRate)
              },
         ],
         lineStyle: {
           type: 'solid'   // 默认为实线,   dashed: 虚线   dotted: 点线
         }
       }
       this.chartInatance.setOption(option);
     },
     getStoreInfoInit() {
       this.charStoretInatance = this.$echarts.init(document.getElementById('storeId'));
       var option = {
         title: {
           text: '店铺销售额',  
           textStyle: {
             color: 'black'  //  设置字体颜色为红色
           },
           left: "45%",   //  距离左侧 30px
           top: 20,   // 距离底部10 px
         },
         toolbox: {    // 设置保存图片
           show: true,
           feature: {
             saveAsImage: {},  // 导出图片 (不需要配置任何项)
             dataView: {readOnly: false},  // 数据视图
             dataZoom: {},   // 区域缩放
             magicType: {
               type: ['bar', 'line']    // 在折线图和柱状图之间切换
             },
             restore: {},    // 重置功能  (还原数据功能)
           },
           x:'right',
           y:'top'
         },
         tooltip: {
           trigger: 'axis'
         },
         legend: {      // legend:  图例进行筛选
           orient: 'vertical',
           x:'right',
           y:'center',
           padding:[ 0, 30, 0, 0 ],
           icon: 'circle',
           data: this.legendList  // legend:  图例中的data 是一个数组。
         },
         grid: {
           show: true,
           left: 100,
           top: 80,
           bottom: 150,
           width: '90%',
           height: 500,
         },
         xAxis: {
           type: 'category',
           scale: true,   // 脱离 0 缩放比
           data: this.storeDateList,  
           axisLabel: {
             interval: 0, // 坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
             rotate: 15, // 调整数值改变倾斜的幅度（范围-90到90）
           },
           boundaryGap: false   // 设置为 false
         },
         yAxis: {
           type: 'value',
           scale: true,  // 脱离 0 值缩放比
         },
         series: this.serise,
         lineStyle: {
           type: 'solid'   // 默认为实线,   dashed: 虚线   dotted: 点线
         }
       }
       this.charStoretInatance.setOption(option); 
     },
   }
}
</script>

<style lang="scss" scoped>
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-table {
        margin-top: 25px;
        margin-bottom: 50px;
      }
      .el-select {  
        margin-right: 20px;
      }
    }
  }
  .echartClass {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    height: 650px;
    border-radius: 20px;
  }
  .storeClass {
    width: 100%;
    height: 650px;
    border-radius: 20px;
  }
</style>